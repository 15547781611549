<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-view">

    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <div id="user-data" >

      <vx-card title="Account" class="mb-base">

        <!-- Avatar -->
        <div class="vx-row">

          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4" v-if="user_data.profileImageUrl">
              <img :src="user_data.profileImageUrl" class=" imgfit" />
            </div>
            <div class="img-container mb-4 mt-1" v-else>
              <img src="https://freshclinics-bucket.s3.ap-southeast-2.amazonaws.com/undefined/k3r1nm7bpf921595963499155.png" class="imgfit" />
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ user_data.fullName }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ user_data.email }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Contact Number</td>
                <td>{{ user_data.contactNumber || "N/A" }}</td>
              </tr>
              <tr>
                <td class="font-semibold">WWCC</td>
                <td>{{ user_data.wwcc || "N/A" }}<span v-if="user_data.tempProfile && user_data.tempProfile.length > 0"><br/> {{ user_data.tempProfile[0].wwcc  }}  <vs-button size="medium" type="line" @click="verifyWWCC">Verify</vs-button>
           </span></td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
            <tr>
                <td class="font-semibold">Date of Birth</td>
                <td>{{ user_data.dateOfBirth ? moment(user_data.dateOfBirth).format('DD/MM/YYYY') : 'N/A'}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ user_data.status }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Profile Completed</td>
                <td>
                    <vs-icon v-if="user_data.profileCreated" icon="check_circle" size="small" color="#0b55bd"></vs-icon>
                    <vs-icon v-else icon="cancel" size="small" color="#bd0b0b"></vs-icon>
                </td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button icon-pack="feather" icon="icon-edit" class="mr-4" :to="{name: 'EditTutorDetails', params: { tutorId: $route.params.tutorId }}">Edit</vs-button>
            <vs-button type="border" color="danger" v-if="user_data.status ==='Enabled'" icon-pack="feather" icon="icon-slash" @click="confirmDeleteRecord">Disable</vs-button>
          </div>

        </div>

      </vx-card>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Tutoring Information" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Tutoring Location</td>
                <td>{{ user_data.tutoringLocation ? user_data.tutoringLocation.displayAddress : "N/A" }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Languages</td>
                <td>{{ user_data.languageName ? user_data.languageName.join(", ") : "N/A"}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Subjects</td>
                <td>{{ user_data.subjectName ? user_data.subjectName.join(", ") : "N/A"}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Rate</td>
                <td> {{"$"+ user_data.rate || "N/A"}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Average Ratings</td>
                <td>{{user_data.averageRating ? user_data.averageRating.toFixed(2) : 0}}<span style="font-size:0.7em;">({{user_data.totalRatingsCount }})</span></td>
              </tr>
            </table>
          </vx-card>
          <vx-card title="Work Experiences" class="mb-base">
            <p v-if="user_data.workExperiences < 1">N/A</p>

            <table v-for="(workExperience, index) in user_data.workExperiences" :key="workExperience">
              <p class="font-bold"><u>Work Experience #{{index + 1}}</u></p>
              <tr>
                <td class="font-semibold">Company</td>
                <td>{{ workExperience.company || 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Title</td>
                <td>{{ workExperience.title || 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Started At</td>
                <td>{{ workExperience.startedAt ? moment(workExperience.startedAt).format('MM/YYYY') : 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Ended At</td>
                <td>{{ workExperience.endedAt ? moment(workExperience.endedAt).format('MM/YYYY') : 'N/A' }}</td>
              </tr>
            </table>
          </vx-card>
        </div>
<div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Qualifications" class="mb-base">
            <p v-if="user_data.qualifications.length < 1">N/A</p>
            <table v-for="(qualification, index) in user_data.qualifications" :key="qualification">
              <p class="font-bold"><u>Qualification #{{index + 1}}</u></p>
              <tr>
                <td class="font-semibold">Institute</td>
                <td>{{ qualification.institute || 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Title</td>
                <td>{{ qualification.title || 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Subject</td>
                <td>{{ qualification.subject || 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">atar</td>
                <td>{{ qualification.atar || 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Started At</td>
                <td>{{ qualification.startedAt ? moment(qualification.startedAt).utc().format('MM/YYYY') : 'N/A'}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Ended At</td>
                <td>{{ qualification.endedAt ? moment(qualification.endedAt).format('MM/YYYY') : 'N/A'}}</td>
              </tr>
            </table>
          </vx-card>
          <vx-card title="Licenses" class="mb-base">
            <p v-if="user_data.licenses < 1">N/A</p>

            <table v-for="(license, index) in user_data.licenses" :key="license">
              <p class="font-bold"><u>License #{{index + 1}}</u></p>
              <tr>
                <td class="font-semibold">Issuing Organization</td>
                <td>{{ license.issuingOrganization || 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Title</td>
                <td>{{ license.title || 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Issued At</td>
                <td>{{ license.issuedAt ? moment(license.issuedAt).format('MM/YYYY') : 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Ended At</td>
                <td>{{ license.expiredAt ? moment(license.expiredAt).format('MM/YYYY') : 'N/A' }}</td>
              </tr>
            </table>
          </vx-card>
</div>
      </div>


      <!-- Availabilities -->
      <vx-card>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Availabilities</span>
            </div>
            <vs-divider />
          </div>
        </div>

        <div class="block overflow-x-auto">
          <table class="w-full permissions-table">
            <tr>
              <!--
                You can also use `Object.keys(Object.values(data_local.permissions)[0])` this logic if you consider,
                our data structure. You just have to loop over above variable to get table headers.
                Below we made it simple. So, everyone can understand.
               -->
              <th class="font-semibold text-base text-left px-3 py-2" v-for="heading in ['Days', 'Morning', 'Evening']" :key="heading">{{ heading }}</th>
            </tr>

            <tr v-for="(availability) in user_data.availabilities" :key="availability">
              <td class="px-3 py-2">{{ availability.day | capitalize}}</td>
              <td class="px-3 py-2">
                <pre>{{availability.morning.join("\r\n")}}</pre>
              </td>
              <td class="px-3 py-2">
                <pre>{{availability.afternoon.join("\r\n")}}</pre>
              </td>
            </tr>
          </table>
        </div>

      </vx-card>
    </div>
  </div>
</template>

<script>
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'
import { mapActions } from 'vuex';
import moment from 'moment'
export default {
  data() {
    return {
      user_data: {},
      user_not_found: false,
    }
  },
  computed: {

  },
  methods: {
    ...mapActions('tutor', ['fetchTutorById','updateTutorById']),
    moment(date){
      return moment(date)
    },
    getTutorDetails() {
      let self = this;
      this.fetchTutorById(self.$route.params.tutorId).then(async res => {
        self.user_data = res.data.data;
        await self.orderAvailabilities()
        console.log(self.user_data)
      })
    },
    orderAvailabilities(){
      this.user_data.availabilities.forEach(function(availability){
        availability.morning = []
        availability.afternoon = []
        availability.availableTimes.forEach(function(availableTime){
            if(parseInt(availableTime.startTime.substring(0,2)) < 12){
              availability.morning.push(availableTime.startTime + " - " + availableTime.endTime)
            }
            else{
              availability.afternoon.push(availableTime.startTime + " - " + availableTime.endTime)
            }
        })
        if(availability.morning.length === 0){
          availability.morning.push("N/A")
        }
        if(availability.afternoon.length === 0){
          availability.afternoon.push("N/A")
        }
      })
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Disable`,
        text: `Are you sure you want to disable this user?`,
        accept: this.deleteDetailHandler,
        acceptText: "Yes"
      })
    },
    verifyWWCC(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Verify WWCC`,
        text: `Are you sure you want to verify this WWCC?`,
        accept: this.verifyWWCCHandler,
        acceptText: "Yes"
      })
    },
    verifyWWCCHandler(){
      let tutorId = this.$route.params.tutorId
      let self = this;
      let tutorData = { wwcc:this.user_data.tempProfile[0].wwcc,tempProfile:[] }
      this.updateTutorById({tutorId, tutorData}).then(res => {
        this.$vs.notify({
          title: 'Delete',
          text: 'WWCC updated successfully.',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
        self.getTutorDetails();
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    deleteDetailHandler() {
      let tutorId = this.$route.params.tutorId
      let tutorData = {status : 'Disabled'}
      // let tutorData = {deletedAt : new Date()}
      this.updateTutorById({tutorId, tutorData}).then(res => {
        this.$vs.notify({
          title: 'Delete',
          text: 'User disabled successfully',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
        this.$router.replace({name:'Tutors'})
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  async mounted() {
      await this.getTutorDetails()
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

  .imgfit {
    height : 110px;
    width: 110px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 10px;
    object-fit: cover;
  }
</style>
